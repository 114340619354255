html {
  height: 100%;
  background-color: #F1F1F1;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto";
}

body {
  margin: 0;
  height: 100%;
}

#app {
  height: 100%;
}

.totalNum {
  font-weight: 700;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset;
  -webkit-box-shadow: 0 0 0 30px white inset;
}

input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
}

a {
  color: #3498DB;
  font-style: normal;
  text-decoration: none;
}

div::-webkit-scrollbar {
  -webkit-appearance: none;
}

div::-webkit-scrollbar:vertical {
  width: 15px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 4px solid white;
  background-color: rgba(0, 0, 0, 0.21);
}

div::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  display: none;
}

input::-ms-clear {
  display: none;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 600ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  transition: opacity 600ms ease-in;
}

.cross-fade-leave {
  opacity: 1;
}
.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.cross-fade-enter {
  opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}


.tippy-tooltip-content {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 600,
}

* { -webkit-tap-highlight-color:rgba(0,0,0,0); }
