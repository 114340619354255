@charset "UTF-8";

@font-face {
  font-family: "untitled-font-5";
  src:url("fonts/untitled-font-5.eot");
  src:url("fonts/untitled-font-5.eot?#iefix") format("embedded-opentype"),
    url("fonts/untitled-font-5.woff") format("woff"),
    url("fonts/untitled-font-5.ttf") format("truetype"),
    url("fonts/untitled-font-5.svg#untitled-font-5") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "untitled-font-5" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "untitled-font-5" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icn-comment:before {
  content: "\61";
}
.icon-icn-group:before {
  content: "\62";
}
.icon-icn-user:before {
  content: "\63";
}
.icon-icn-tiles:before {
  content: "\64";
}
.icon-icn-notification:before {
  content: "\65";
}
.icon-icn-notes:before {
  content: "\66";
}
.icon-icn-list:before {
  content: "\67";
}
.icon-icn-lifesaver:before {
  content: "\68";
}
.icon-icn-help:before {
  content: "\69";
}
